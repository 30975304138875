import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import DatePicker from "react-datepicker";//need to remove
import "react-datepicker/dist/react-datepicker.css";
import CheckBox from './../../elements/CheckBox';
import Button from './../../elements/Button';
import Accordion from './../../elements/Accordion';
import ModalPopup from './../../elements/Popup';
import { DealerService } from './../../../service';
import AddSKU from './AddSKU';
import SubscriptionHistoryLog from './SubscriptionHistoryLog';
import SkuBox from './SkuBox';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import secureStorage from '../../../config/encrypt';
import { DEALER_WALLET_ID} from './../../../config/constant';



class SubscriptionDetails extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        
        this.state = {
            viewonly: props.role=="dealercentral",
            key: 1,
            role: "",
            loading:true,
            startDate: new Date(),
            isOpen: false,
            showmodal: false,
            showSubscritionHistoryModal:false,
            dealer_id_hash:"",
            basic_details:{},
            owner_details:{},
            usedSkus:[],
            skus: [],
            subscriptions: [],
            editDataAll: [],
            skuNew:[],
            editSkuExpired:[],
            editSkuExisting:[],
            showConfirmModal: false, 
            bankDetails : [],
            bankAccounts : 0,            
            role_access: props.role_access || [],
            // openLeads : false
        };    
    
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.owner_details) this.setState({  owner_details: nextProps.owner_details });
        if(nextProps.basic_details) this.setState({  basic_details: nextProps.basic_details,role_access: nextProps.role_access || [] });
    }
    showModal = () => {
        this.setState({ showmodal: true });
    }
    showSubscritionHistoryModal = () => {
        this.setState({ showSubscritionHistoryModal: true });
    }
    closeSubscritionHistoryModal = () => {
        this.setState({ showSubscritionHistoryModal: false });
    }
    closeModal = () => {
        this.setState({ showmodal: false });
    }

    showConfirmModal = () => {
        this.setState({ showConfirmModal: true });
    }
    closeConfirmModal = () => {
        this.setState({ showConfirmModal: false });
    }
    
    submitModal = (data) => {
        // let obj = [];
        // if (data.id == 1 && data) {
        //     for (let field of data.fields) {
        //         if (field.attr_id == 43 || field.attr_id == 44)
        //             obj.push(
        //                 { attr_attribute_code: field.attr_attribute_code, bd_attr_attribute_id: field.attr_id, bd_attr_attribute_value: field.fields[0].attr_id }
        //             );
        //     }
        //     this.state.skuNew.push({master: data, "fields.sub_status": "FREE","fields.pack_type": "ACTIVE_LIST",fields:obj, _key:Math.random()});
        // }
        // else{
            this.state.skuNew.push({master: data, _key:Math.random()});
        // }
        let usedSkus = this.state.usedSkus || []
        usedSkus.push(data.id)
        this.setState({ showmodal: false, usedSkus: usedSkus });
    }
    // handleChange = date => {
    //     this.setState({
    //         startDate: date
    //     });
    // };

    handleSelect(key) {
        this.setState({ key });
    }

    // handleChange = (sname, ovalue) => {
    //     this.setState({
    //         [sname]: ovalue.value
    //     });
    // }
    handelError(err) {
        this.setState({loading:false})
    }
    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    componentDidMount() {
        this.setState({ dealer_id_hash: this.props.dealer_id_hash });
        if(this.props.basic_details) this.setState({ basic_details: this.props.basic_details });
        if(this.props.owner_details) this.setState({owner_details : this.props.owner_details})
        this.getBasicDetails()
        this.getDealerKycDetail();
        // this.checkOpenLeads({dealer_id : this.props.dealer_id_hash, source : "GCLOUD"})  
    }

    /* checkOpenLeads(params) {
        DealerService.checkOpenLeads(params)
            .then(result => {
                if (result && result.data && result.data.status === 200) {
                    console.log(result)
                    if (result.data.data.open_leads === 0) {
                        this.setState({ openLeads: false })
                    } else {
                        this.setState({ openLeads: true })
                    }
                } else {
                    this.setState({ openLeads: true })
                }
            })
            .catch(error => {
                console.log(error)
                toast.error(error);
            })
    } */

     getDealerKycDetail = async () => {
        DealerService.getKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                let tempkycDealerData = [];
                let activeAccounts = 0;
                let address1 = '';
                let address2 = '';
                if (result.data && result.data.data && result.data.data.length > 0) {
                    tempkycDealerData = result.data.data
                    for (let i = 0; i < tempkycDealerData.length; i++) {
                        if (tempkycDealerData.bank_address != '') {
                            address1 = tempkycDealerData[i].bank_address.split('|')[0];
                            address2 = tempkycDealerData[i].bank_address.split('|')[1];
                        }
                        tempkycDealerData[i].address1 = address1;
                        tempkycDealerData[i].address2 = address2;
                    }
                }
                activeAccounts =  tempkycDealerData.reduce((acc, curr)  => {
                    if(curr.active_status === '1'){
                         return acc += 1;
                    }else{
                         return acc += 0;
                    }
                }, activeAccounts)
                this.setState({ bankDetails: tempkycDealerData, bankAccounts : activeAccounts, loading: false })
            }
            else {
                this.setState({ loading: false })
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            this.setState({ loading: false })
            toast.error(error.message || "Some Error Occurred");
        });
    }


    getBasicDetails(){
        DealerService.getMasters(["dealer_sku"]).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                let responsedata = response.data.data || {};
                if (responsedata.dealer_sku && responsedata.dealer_sku.length) {
                    let subscM = [], skuM = [];
                    // eslint-disable-next-line array-callback-return
                    responsedata.dealer_sku.map(v => {
                        if (v.sku_type == "subscription") subscM.push(v);
                        if (v.sku_type == "service") skuM.push(v);
                    })
                    this.setState({ subscriptions: subscM ,loading:false});
                    this.setState({ skus: skuM });
                }
            }
            // return DealerService.getSkusDetails(this.state.dealer_id_hash);
            return this.fetchEditData();
        }).then((response) => {
            // if (response.status == 200 && response.data.status == 200) {
            //     if (response.data.data) {
            //         let editDataAll = response.data.data;
            //         this.setState({ editDataAll: editDataAll })
            //         this.setState({ editSkuExpired: editDataAll.filter(v => this.isSKUType(v, 'service', 'expired')).map(v => { return {...v, master:this.getMaster(v)} }) })
            //         this.setState({ editSkuExisting: editDataAll.filter(v => this.isSKUType(v, 'service', 'existing')).map(v => { return {...v, master:this.getMaster(v)} }) })
            //     }
            // }
        }).catch((error) => {
            this.setState({loading:false})
        });
    }
    getMaster = (row) => {
        return this.state.skus.filter(v => v.id == row.b_details_sku_id)[0]
    }
    isSKUType(row, type, status_type) {
        let skuInfo = this.state.skus.filter(v => {
            return (v.id == row.b_details_sku_id && v.sku_type == type && row.status_type == status_type)
        })
        return (skuInfo && skuInfo[0]) ? true : false;
    }
    fetchEditData = () => {
        return new Promise((resolve, reject) => {
            this.setState({loading:true});
            DealerService.getSkusDetails(this.state.dealer_id_hash).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    let editDataAll = response.data.data || [];
                    let usedSkus = editDataAll.map(v => v.b_details_sku_id)
                    usedSkus = [...new Set(usedSkus)];
                    this.setState({ editDataAll: editDataAll, skuNew: [], usedSkus:usedSkus ,loading:false})
                    this.setState({ editSkuExpired: editDataAll.filter(v => this.isSKUType(v, 'service', 'expired')).map(v => { return {...v, master:this.getMaster(v), _key: Math.random()} }) })
                    this.setState({ editSkuExisting: editDataAll.filter(v => this.isSKUType(v, 'service', 'existing')).map(v => { return {...v, master:this.getMaster(v), _key: Math.random()} }) })
                    resolve();
                }
                else {
                    reject(response.data)
                }
            }).catch(reject);
        })
    }
    newSkuOnChange = (idx) => (newVal) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.skuNew[idx] = newVal;
        // let errors = this.state.skuNew.map(v => v.errors).filter(v => v);//need to remove
        // if (errors.length != 0) return toast.error(`Please fix error in form.`);
    }
    existingSkuOnSave = (idx) => (existingVal) => {
        if(existingVal && !existingVal.errors) {
            this.setState({loading:true});
            var regex = /[.,\s]/g;
            if(existingVal.b_details_selling_price) existingVal.b_details_selling_price=(''+existingVal.b_details_selling_price).replace(regex,'');
            return DealerService.addBookingDetails([existingVal], this.state.dealer_id_hash).then(result => {
                if (result && result.data && result.data.status == 200) {
                    this.setState({loading:false})
                    if(existingVal.bulk_update != 1){                        
                        toast.success(result.data.message || "Successfully Updated")
                    }                    
                    this.fetchEditData().then().catch(error => {
                        this.handelError(error);
                    });
                }
                else if (result && result.data && result.data.status == 400) {
                    this.setState({loading:false})
                    toast.error(result.data.message || "Unable to update")
                }
            }).catch(error => {
                this.setState({loading:false})
            });
        }
        else {
            console.log("existingVal.errors", idx, existingVal)
            this.setState({loading:false})
            return toast.error(`Please fill all required filed.`);
        }
    }
    showDevInfo = (event) => {
        if (event.target == event.currentTarget) this.setState({ showDevCount: (this.state.showDevCount || 0) + 1 });
    }
    expiredSkuOnSave = this.existingSkuOnSave
    expiredSkuOnChange = (idx) => (expiredVal) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.editSkuExpired[idx] = expiredVal;
        // let errors = this.state.editSkuExpired.map(v => v.errors).filter(v => v);//need to remove
        // if (errors.length != 0) return toast.error(`Please fix error in form.`);
    }
    newSkuhandelSubmit = (event) => {
        event.preventDefault()
        let submit_status=1;
        let errors = this.state.skuNew.map(v => v.errors).filter(v => v);
        console.log("existingVal.errors", errors)

        if (errors.length != 0) return toast.error(`Please fill all required filed.`);
        this.setState({loading:true})
        if (this.state.skuNew.length > 0) {
            var regex = /[.,\s]/g;
            this.state.skuNew.forEach((skunewdata, index) => {
                if(skunewdata.b_details_selling_price ==null || skunewdata.b_details_selling_price ==''){
                    submit_status=0;
                }else{
                    // eslint-disable-next-line react/no-direct-mutation-state
                    this.state.skuNew[index].b_details_selling_price = (''+skunewdata.b_details_selling_price).replace(regex,'');
                }
                if(skunewdata.b_details_validity_from==null || skunewdata.b_details_validity_from==''){
                    submit_status=0;
                }
                if(skunewdata.b_details_validity_to==null || skunewdata.b_details_validity_to==''){
                    submit_status=0;
                }
                if(!skunewdata.fields || skunewdata.fields.length==0 || !skunewdata.fields.filter(v => v.attr_attribute_code == "_no_of_listing")[0]) {
                    submit_status=0;
                   //return toast.error(`Please Provide Number.`);
                }
            })
        }
        if(submit_status){
            return DealerService.addBookingDetails(this.state.skuNew, this.state.dealer_id_hash).then(result => {

                if (result && result.data && result.data.status == 200) {
                    this.setState({loading:false})
                    toast.success(result.data.message || "Successfully Created");
                    this.fetchEditData().then().catch(error => {
                        this.handelError(error);
                    });
                }
                else if (result && result.data && result.data.status == 400) {
                    this.setState({loading:false})
                    toast.error(result.data.message || "Unable to create multiple buyer listing at a time ");
                }
                else {
                    this.handelError(result.data);
                }
    
            }).catch(error => {
                
                this.handelError(error);
            });
        }else {
            this.setState({loading:false})
            return toast.error(`Please fill all required filed.`);
        }
        
    }
    info_sync_bd = "Sync all subscriptions and implement in inventory."
    syncBookingDetails = () => {
        console.log("this.props.basic_details", this.props.basic_details);
        var e_sync_bd = document.getElementById("sync_bd");
        e_sync_bd.classList.add("loading");
        return DealerService.syncBookingDetails({dealer_ids: this.props.basic_details.id}).then(result => {
            e_sync_bd.classList.remove("loading");
        }).catch(error => {
            e_sync_bd.classList.remove("loading");
            console.log("5554555554 error", error)
        })

    }
    removeRowNew = (idx) => (item)=> {
        if(idx > -1 && this.state.skuNew[idx]) {
            let usedSkus = this.state.usedSkus || []
            let sku_id = this.state.skuNew[idx].master.id
            usedSkus = usedSkus.filter(id => id != sku_id);
            this.state.skuNew.splice(idx, 1)
            this.setState({
                usedSkus: usedSkus,
                skuNew: this.state.skuNew
            });
        }
    }

    expireBuyerListing = () => {   
        this.closeConfirmModal();     
        let buyer_listing_arr = [];
        // eslint-disable-next-line array-callback-return
        this.state.editSkuExisting.map( (skuData) => {            
            if(skuData.sku_short_name == "buyer_listing" && skuData.b_details_sku_id == 1){
                //console.log(skuData);
                //return false;
                let buyer_listing_data = {...skuData}; 
                buyer_listing_data.bulk_update = 1;
                if(buyer_listing_data.fields){
                    buyer_listing_data.fields = buyer_listing_data.fields.map((listing_fields) => {
                        if(listing_fields.attr_attribute_code == 'sub_status'){
                            listing_fields.bd_attr_attribute_value = 'FREE';
                        }
                        return listing_fields;
                    })
                }                
                var d = new Date();
                d.setDate(d.getDate() - 1);
                d.setHours(23,59,59,999);
                buyer_listing_data.b_details_validity_to = d; 
                buyer_listing_arr.push(buyer_listing_data);                             
            }            
        });   

        if(buyer_listing_arr.length){
            // eslint-disable-next-line array-callback-return
            buyer_listing_arr.map( (data, index) => {
                this.existingSkuOnSave(index)(data);  
            }) 
        } 
                
        toast.success("Successfully Updated");
    }
    // handleChangetext = (event) => {
    //     this.setState({
    //         [event.target.name]: event.target.value
    //     });
    // }
    render() {
        let basicinfo = this.state.basic_details;
        const { role_access } = this.state;
        return (
        <div>
            <div className="row">
                <div className="col-sm-6">
                    <h1>{this.props.t('dealerDetails.myAccount.Subscription_Details')} 
                    <span className="f12">({basicinfo.organization} - {basicinfo.gcd_code})</span>
                    <span className="f12">
                        {
                            (basicinfo.vendor_id) ? <span> Vendor ID - {basicinfo.vendor_id}</span> : ''
                        }
                    </span>
                    </h1>
                </div>
                {!this.state.viewonly && 
                    <div className="col-sm-6 text-right" onClick={this.showDevInfo}>
                        {this.state.showDevCount>=3 && 
                            <a id="history_model_a" onClick={this.showSubscritionHistoryModal} style={{ cursor: "pointer", color: "#0000008a" }} href={() => false}>{this.props.t('dealerDetails.subscription_details.subscription_history')}</a>
                        }

                        {
                            role_access.includes("subscription_sync") ? <a id="sync_bd" onClick={this.syncBookingDetails} style={{cursor:"pointer", margin:"10px 20px", color: "#0000008a"}} title={this.info_sync_bd} href={() => false}>sync</a>: ''
                        }
                        {
                            role_access.includes("sub_expire_buyer_list") ? <Button type="button" btnClass="btn btn-success" onClick={this.showConfirmModal}>{this.props.t('dealerDetails.subscription_details.expire_buyer_listing')}</Button>: ''
                        }
                        &nbsp;&nbsp;
                        {
                            role_access.includes("add_sku") ? <Button type="button" btnClass="btn btn-success" onClick={this.showModal}>{this.props.t('dealerDetails.subscription_details.add_sku_btn')}</Button>: ''
                        }
                    </div>
                }
            </div>

            <div className="card">
                {/* <div className="card-heading">
                    <div className="row">
                        <div className="col-sm-7"><h2 className="card-title">Subscription Details</h2></div>                        
                        <div className="col-sm-5 text-right">
                            <button type="button" className="btn btn-link" title="Other Settings" onClick={this.showModal}>+ Add SKU</button>
                        </div>
                    </div>
                </div>                     */}
                <div  className={(this.state.loading) ? "card-body loading" : "card-body"}>
                    <Accordion title={this.props.t('dealerDetails.subscription_details.Subscription_Services')} active="true">
                        <form name="finance_form" id="finance-0-form">
                            <div className="form-inline rc-inline">
                                <SubscriptionCheckBox 
                                        baiscDetails={this.state.basic_details}
                                        ownerDetails={this.state.owner_details}
                                        bankDetails={this.state.bankDetails}
                                        bankAccounts={this.state.bankAccounts}
                                        subscriptionsM={this.state.subscriptions}
                                        subscriptionsEdit={this.state.editDataAll}
                                        dealer_id_hash={this.props.dealer_id_hash}
                                        viewonly={this.state.viewonly}
                                        fetchEditData={this.fetchEditData}
                                        // openLeads={this.state.openLeads}
                                    />
                            </div>
                        </form>
                    </Accordion>
                    {this.state.skuNew.length > 0 && (
                    <Accordion title={this.props.t('dealerDetails.subscription_details.New_Sku')} active="true">
                        <div>
                            <form name="form_new_skus">
                                <div className="row">
                                    {this.state.skuNew.map((v, k) => 
                                        <SkuBox onChange={this.newSkuOnChange(k)} removeRow={this.removeRowNew(k)} key={`create_${v._key}`} sku={v} dealer_id_hash={this.get_dealer_id_hash} isOpen={true} card_type="create" />
                                    )}
                                </div>
                                <div className="card-footer text-center">
                                    {!this.state.viewonly && <Button onClick={this.newSkuhandelSubmit} type="button" btnClass="btn btn-success" ><i className="sprite ic-save mrg-r15"></i>{this.props.t('dealerDetails.subscription_details.Save')}</Button>}
                                </div>
                            </form>
                        </div>
                    </Accordion>
                    )}
                    <Accordion title={`${this.props.t('dealerDetails.subscription_details.Existing_Sku')}  (${this.state.editSkuExisting.length})`} active="true">
                        <div>
                            <form>
                                <div className="row">
                                    {this.state.editSkuExisting.map((v, k) => 
                                        <SkuBox onChange={() => {}} onSaveClick={!this.state.viewonly && this.existingSkuOnSave(k)} key={`existing${v._key}`} sku={v} dealer_id_hash={this.dealer_id_hash} card_type="existing" viewonly={this.state.viewonly}></SkuBox>
                                    )}
                                </div>
                            
                            </form>
                        </div>
                    </Accordion>
                    <Accordion title={`${this.props.t('dealerDetails.subscription_details.Expired_Sku')} (${this.state.editSkuExpired.length})`}>
                        <div>
                            <form>
                                <div className="row">
                                    {this.state.editSkuExpired.map((v, k) => 
                                        // <SkuBox onChange={() => {}} onSaveClick={!this.state.viewonly && this.expiredSkuOnSave(k)} key={`expire${v._key}`} sku={v} dealer_id_hash={this.dealer_id_hash} card_type="expire" viewonly={this.state.viewonly}></SkuBox>
                                        <SkuBox onChange={() => {}} key={`expire${v._key}`} sku={v} dealer_id_hash={this.dealer_id_hash} card_type="expire" viewonly={true}></SkuBox>
                                    )}
                                </div>
                            
                            </form>
                        </div>
                    </Accordion>

                </div>

                <div className="card-footer text-center">
                                    {/* <Button btnClass="btn btn-default mrg-r30" ><i className="sprite ic-prev-b"></i> {this.props.t('dealerDetails.Outlet_Details_master.Previous')}</Button> */}
                                    <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30">{this.props.t('dealerDetails.Outlet_Details_master.Previous')} <i className="ic-arrow_back"></i></NavLink>
                                    <NavLink to={this.props.nextpage} className="btn btn-primary">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink>
                    </div>
                </div>
                {this.state.showmodal == true &&
                    <ModalPopup id="EditBuyerDetails" className="modal " title={this.props.t('dealerDetails.subscription_details.Add_Subscription')} modalClose={this.closeModal}>
                        <AddSKU skus={this.state.skus} modalClose={this.closeModal} modalSubmit={this.submitModal}/>
                    </ModalPopup>
                }
                {this.state.showSubscritionHistoryModal == true &&
                    <ModalPopup id="SubscriptionHistoryLog" className="modal modal-xl" title={this.props.t('dealerDetails.subscription_details.subscription_history_title')} modalClose={this.closeSubscritionHistoryModal}>
                        <SubscriptionHistoryLog  dealer_id_hash = {this.props.dealer_id_hash} modalClose={this.closeSubscritionHistoryModal} />
                    </ModalPopup>
                }
                {this.state.showConfirmModal == true &&
                    <ModalPopup id="confirm_modal" className="modal " title={this.props.t('dealerDetails.subscription_details.expire_buyer_listing')} modalClose={this.closeConfirmModal}>
                        <div>
                            <p>{this.props.t('dealerDetails.subscription_details.expiry_confirm_msg')}</p>                            
                            <div className="col-sm-6 col-md-12 form-group text-right">
                                <Button type="button" onClick={this.expireBuyerListing} colclass="col-sm-12" btnClass="btn btn-success" name="ok" id="ok" title={this.props.t('dealerDetails.subscription_details.ok')} />
                                &nbsp;&nbsp;
                                <Button type="button" onClick={this.closeConfirmModal} colclass="col-sm-12" btnClass="btn btn-primary" name="closeconfirm" id="closeconfirm" title={this.props.t('dealerDetails.subscription_details.Cancel')} />
                            </div>
                        </div>
                    </ModalPopup>
                }
            </div>

        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails));
//export default withTranslation('dealers')(SubscriptionDetails)

class SubscriptionCheckBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscriptionsM:[],
            subscriptionsEdit: []
        };
    }
    componentWillReceiveProps(nextProps) {
        /* set sku subscription */
        let subscriptionsM = nextProps.subscriptionsM;
        if (nextProps.subscriptionsEdit) {
            subscriptionsM = subscriptionsM.map(v => {
                let editData = nextProps.subscriptionsEdit.filter(vEdit => vEdit.b_details_sku_id == v.id)[0] || {};
                editData.master = v;
                return editData;
            });
        }
        this.setState({ subscriptionsM: subscriptionsM });
        /* set sku subscription - END */
    }
    handelError(err) {
        this.setState({loading:false})
    }
    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }

    get list_data() {
        return this.props.fetchEditData()
    }

    validWalletSub(){
        let isValid = true;
        if(this.props.baiscDetails.status !== '1'){
            isValid = false;
            toast.error('User must be active')
        }
        else if(this.props.bankAccounts !== 1){
            isValid = false
            toast.error('Must have single active bank account')
        }
        /* else if(this.props.openLeads !== false){
            isValid = false
            toast.error(`Please close open leads`)
        } */
        return isValid;
    }

    saveSubscriptions(v, b_details_is_active, idx) {
        DealerService.saveSubscriptions(v, this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                const newSubscriptions = this.state.subscriptionsM.map((v, sidx) => {
                    if (idx !== sidx) return v;
                    return { ...v, b_details_is_active: b_details_is_active };
                });
                this.setState({ subscriptionsM: newSubscriptions });
                this.list_data();
            }
            else {
                this.handelError(result.data);
            }
        }).catch(error => { });
    }

    saveWalletHistory(data){
        return new Promise(async (resolve, reject) => {
            try {
                let resp = await DealerService.saveWalletHistory(data);
                if(resp && resp.data && resp.data.status === 200){
                    resolve('success')
                }else{
                    reject(resp)
                }
            } catch (error) {
                reject(error);
            }
        })
    }

    saveWalletSubscription(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let resp = await DealerService.saveWalletSubscriptions(data);
                if (resp && resp.data && resp.data.status === 200) {
                    resolve('success')
                } else {
                    reject(resp)
                }
            } catch (error) {
                reject(error);
            }
        })
    }

    handleChangeSubscription = (idx) => async (e, v) => {
        let b_details_is_active = e.target.checked ? '1' : '0';
        v = Object.assign({}, v, { b_details_is_active: b_details_is_active });
        if (b_details_is_active === '0' && v.master.id === DEALER_WALLET_ID) {
            return;
        } else if (b_details_is_active === '1' && v.master.id === DEALER_WALLET_ID) {
            if (this.validWalletSub()) {
                let dealer_data = {
                    dealer_id: this.props.baiscDetails.id,
                    dealer_name: this.props.baiscDetails.organization,
                    dealer_contact: this.props.ownerDetails.mobile,
                    is_subscribed: '1',
                }
                this.saveSubscriptions(v, b_details_is_active, idx); 
                this.saveWalletHistory(dealer_data);
            }
            return;
        }else {
            this.saveSubscriptions(v, b_details_is_active, idx); 
        }
    }
    componentDidMount() {
        // this.setState({ dealer_id_hash: this.props.dealer_id_hash });
    }
    conformPopup(e, row) {
        if (!e.target.checked && row.master.id === DEALER_WALLET_ID) {
            return;
        } else {
            let msg = e.target.checked ? `Are you sure to subscribe ${row.master.sku_name}?` : `Are you sure to un-subscribe ${row.master.sku_name}?`
            return window.confirm(msg)
        }
    }

    checkedCondition(v, idx) {
        if(!v.b_details_is_active || ( v.b_details_is_active === '1' && v.master.id === DEALER_WALLET_ID && this.props.baiscDetails.status === '2')){
            return false;
        }else if(v.b_details_is_active === '0' && v.master.id === DEALER_WALLET_ID){
            return false;
        }else if(v.b_details_is_active === '0' && v.master.id !== DEALER_WALLET_ID){
            return false;
        }
        return true;
    }

    render() {
        let user_type_id = 0
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                user_type_id = loginResponse.data.user_data.user_type_id;
            }
        }
        return (
                Object.keys(this.props.baiscDetails).length > 0 && this.state.subscriptionsM.map((v, idx) => 
                    {
                    return <CheckBox type="checkbox" disabled={v.master.id === DEALER_WALLET_ID && this.props.baiscDetails.status !== '1' ? true : false} checked={this.checkedCondition(v,idx)}
                        name={v.master.sku_short_name} id={v.master.sku_short_name} label={v.master.sku_name} key={v.master.sku_short_name}
                        onChange={ (e) => {  if (!this.props.viewonly && user_type_id != 62 && this.conformPopup(e, v)) this.handleChangeSubscription(idx)(e, v)}} />

                    }
                )
        )
    }
}