import common_id from "./id/common.json";
import common_en from "./en/common.json";
import common_en_ph from "./en/common_ph.json";

import inventory_id from "./id/inventory.json";
import inventory_en from "./en/inventory.json";
import inventory_en_ph from "./en/inventory_ph.json";

import dealer_id from "./id/dealer.json";
import dealer_en from "./en/dealer.json";
import dealer_en_ph from "./en/dealer_ph.json";

import lead_id from "./id/lead.json";
import lead_en from "./en/lead.json";
import lead_en_ph from "./en/lead_ph.json";

import ac_manager_id from "./id/ac_manager.json";
import ac_manager_en from "./en/ac_manager.json";
import ac_manager_en_ph from "./en/ac_manager.json";

import sales_support_users_id from "./id/sales_support_users.json";
import sales_support_users_en from "./en/sales_support_users.json";
import sales_support_users_en_ph from "./en/sales_support_users.json";
import inventory_funding_en from './en/inventory_funding.json';
import inventory_funding_id from './id/inventory_funding.json';

// export default {//need to remove
const defaultTranslation =  {
            common_id,common_en,common_en_ph,
            inventory_id,inventory_en,inventory_en_ph,
            dealer_id,dealer_en,dealer_en_ph,
            lead_id,lead_en,lead_en_ph,
            ac_manager_id, ac_manager_en, ac_manager_en_ph,
            sales_support_users_id,sales_support_users_en,sales_support_users_en_ph,
            inventory_funding_en, inventory_funding_id
        };

export default defaultTranslation;
