import allConf from "./config";
const conf = allConf[process.env.REACT_APP_CONFIG_FILE_NAME] ? allConf[process.env.REACT_APP_CONFIG_FILE_NAME] : allConf.demo;
export const API_URL = {
    // LEAD_API: "http://localhost:4020/",
    // INVENTORY_API: "http://localhost:4021/",
    // DEALER_API: "http://localhost:4022/",
    GETWAY_API: process.env.REACT_APP_GETWAY_API
    // GETWAY_API: "https://int-gcloud.gaadi.com/"
    // GETWAY_API: "http://192.168.72.36:8626/"
    // GETWAY_API: "http://localhost:8626/"
}

export const OWNER = [
    { value: '0', label: { 'en': '1st Owner', 'id': '1st Owner'} },
    { value: '1', label: { 'en': '2nd Owner', 'id': '2nd Owner' } },
    { value: '2', label: { 'en': '3rd Owner', 'id': '3rd Owner' } },
    { value: '3', label: { 'en': '4th Owner', 'id': '4th Owner' } },
    { value: '4', label: { 'en': 'Four +', 'id': 'Four +' } }
];

export const MONTH = [
    { value: 1, label: {en: 'Jan', id: 'Jan' }},
    { value: 2, label: {en: 'Feb', id: 'Feb' } },
    { value: 3, label: {en: 'Mar', id: 'Mar' } },
    { value: 4, label: {en: 'Apr', id: 'Apr' } },
    { value: 5, label: {en: 'May', id: 'May' } },
    { value: 6, label: {en: 'June', id: 'June' } },
    { value: 7, label: {en: 'July', id: 'July' } },
    { value: 8, label: {en: 'Aug', id: 'Aug' } },
    { value: 9, label: {en: 'Sep', id: 'Sep' } },
    { value: 10, label: {en: 'Oct', id: 'Oct' } },
    { value: 11, label: {en: 'Nov', id: 'Nov' } },
    { value: 12, label: {en: 'Dec', id: 'Dec' } }
];

export const APIV = 2;
export const SITE_CONTENTS = {
    DEALER_SUPPORT_EMAIL: conf.DEALER_SUPPORT_EMAIL,
    DEALER_SUPPORT_PHONE: conf.DEALER_SUPPORT_PHONE,
    SITE_NAME: conf.SITE_NAME,
    SITE_ADDRESS: conf.SITE_ADDRESS,
    SITE_COPYRIGHT:conf.SITE_COPYRIGHT
};
export const LINK_URL = {
    LOGO: conf.LOGO,
    GOOGLE_PLAY: conf.GOOGLE_PLAY,
    APP_STORE: conf.APP_STORE,
    WHATSAPP_SHARE: conf.ALLOW_WHATSAPP_SHARE
};
export const CONFIG_FILE_CHANGE = {
    DP_BUDGET: conf.DP_BUDGET,
    FAV_ICON: conf.FAV_ICON,
    MOBILE_PREFIX:conf.MOBILE_PREFIX,
    TYPE: conf.TYPE
}

export const STOCK_PAGE_LIMIT = 100;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const REACT_APP_TENANT = process.env.REACT_APP_TENANT;
export const PASSWORD_VALIDATION=/^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/;
//export const MOBILE_VALIDATION=/^[8]\d{8,11}$/;
export const MOBILE_VALIDATION=new RegExp(conf.MOBILE_VALIDATION);
export const BANK_ACCOUNT_NUMBER=new RegExp(conf.BANK_ACCOUNT_NUMBER);
export const BRANCH_NAME=new RegExp(conf.BRANCH_NAME);
export const BENIFICIERY_NAME=new RegExp(conf.BENIFICIERY_NAME);
export const CONF_VAL =conf;
export const BUMP_UP_LIMIT = 1 ;
export const BUMP_UP_EXPIRY_HOURS = 2 ;
export const DESCRIPTION_FIELD_MIN_LENGTH = 100 ;
export const TOTAL_MGF_YEAR = 1970;
export const MIN_STOCK_IMAGE_LIMIT = conf.MIN_STOCK_IMAGE_LIMIT;
export const DISABLE_CERTIFICATION_STATUS_FIELD = conf.DISABLE_CERTIFICATION_STATUS_FIELD;
export const DISABLE_REGISTRATION_NUMBER_TYPE = conf.DISABLE_REGISTRATION_NUMBER_TYPE;
export const WEBSITE_NAME = conf.WEBSITE_NAME;
export const CURRENCY_SYMBOL = conf.CURRENCY_SYMBOL;
export const COUNTRY_CODE = conf.COUNTRY_CODE;
export const SITE_ID_DURING_CLASSIFIED = conf.SITE_ID_DURING_CLASSIFIED;
export const BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG = conf.BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG;
export const DEALER_WALLET_ID = 39
export const CURRENCY_SEPARATOR= ".";

export const RoleId = {
	"51": "RBH",
	"52": "BM",
	"53": "Agent",
	"54": "BRO",
	"55": "Virtual Admin",
	"56": "AM",
	"57": "ARO",
	"58": "ZM",
	"59": "ARCO",
	"50": "VP"
}
export const OWNER_ID = 9

export const BursaDetails = [
    {
        id: 0,
        label: "Non-Bursa",
    },
    {
        id: 1,
        label: "Bursa",
    },
];

export const Reason_For_Inactivate = [
    {
        value: 'closed_bankruptcy',
        label: "Closed/Bankruptcy",
    },
    {
        value: 'moving_to_another_city_region',
        label: "Moving to another city/region",
    },
    {
        value: "gcd_to_be_used_for_one_time_use",
        label: "GCD to be used for one-time use",
    },
    {
        value: 'others',
        label: "Others",
    }
];

export const statusChangeOptions = {
    "2":[{value: 6,label: "Rejected"}, {value:3 ,label: "Incomplete"}, {value:5, label: "Blacklist"}],
    "3":[{value: 6,label: "Rejected"}, {value:5, label: "Blacklist"}],
    "4":[{value:3 ,label: "Incomplete"},{value: 6,label: "Rejected"},{value:5, label: "Blacklist"}],
    "6":[{value:3 ,label: "Incomplete"},{value: 4,label: "Pending"},{value:5, label: "Blacklist"}]
};

export const dealerStatusList = {
    "1":"Active",
    "2":"Inactive",
    "3":"Incomplete",
    "4":"Pending",
    "5":"Blacklist",
    "6":"Reject"
}
export const REDIRECT_URL = window.location.origin