import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from './../../elements/InputField';
import RadioBox from './../../elements/Radiobox';
import { toast } from 'react-toastify';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import secureStorage from './../../../config/encrypt';
class ShareLead extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shareData: {},
            share_type: 'email',
            carDetails: [],
            leadSharePostData: {},
            errors: {},
            share_option: 'reminder',
            dealerInfo: {},
            loaderClass: ''
        }
    }

    componentDidMount = () => {
        this.setState({ shareData: this.props.shareData }, () => {
            let leadSharePostData = { ...this.state.leadSharePostData };
            leadSharePostData.dealer_id = this.props.dealer_id;
            leadSharePostData.dealer_id_hash = this.props.dealer_id_hash;
            leadSharePostData.lead_id = this.state.shareData.lead_id;
            leadSharePostData.email = this.state.shareData.lead_email;
            leadSharePostData.share_type = 'Car_details';
            this.setState({ leadSharePostData: leadSharePostData });
            this.getLeadCars();
            this.getDealerDetails();
        });
    }

    getDealerDetails = ()=>{
        MasterService.get('dealer/dealer/my-details').then((response)=>{
            if(response.status == 200 && response.data.status == 200){
                this.setState({dealerInfo: response.data.data },()=>{
                    this.generateMessageBody('reminder');
                });
            }
        });
    }

    handleTabClick = (event) => {
        let selectedTab = event.currentTarget.dataset.key;
        this.setState({ share_type: selectedTab });
    }

    getLeadCars = async () => {
        await MasterService.post('lead/lead/getCarsOnLead', { lead_id: this.state.shareData.lead_id, dealer_id: this.props.dealer_id }).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                let carDetails = response.data.data;
                this.setState({ carDetails: carDetails });
            }
        });
    }

    handleCheckboxChange = (event) => {
        let leadSharePostData = { ...this.state.leadSharePostData };
        let errors = { ...this.state.errors }
        let carId = event.target.getAttribute('carid');
        if (!leadSharePostData.car_id) leadSharePostData.car_id = [];
        if (leadSharePostData.car_id.indexOf(carId) !== -1) {
            let index = leadSharePostData.car_id.indexOf(carId);
            leadSharePostData.car_id.splice(index, 1);
            if (leadSharePostData.car_id.length < 1) {
                errors['car_id'] = "Please select a car";
            }
        } else {
            delete errors['car_id'];
            leadSharePostData.car_id.push(carId);
        }
        this.setState({ leadSharePostData: leadSharePostData, errors: errors });
    }

    submitLeadShare = (event) => {
        event.preventDefault();
        const {share_option, share_type} = this.state;
        if(share_type === 'email'){
            if (this.validateForm()) {
                this.setState({loaderClass: 'loading'});
                let leadSharePostData = { ...this.state.leadSharePostData };
                leadSharePostData['email'] = leadSharePostData.email.split(',');
                MasterService.post('lead/lead/shareLeadEmail', leadSharePostData).then((response) => {
                    if (response.status == 200 && response.data.status == 200) {
                        toast.success(response.data.message);
                        if (typeof this.props.modalClose === 'function') {
                            this.props.modalClose();
                        }
                    }else{
                        this.setState({loaderClass: ''});
                    }
                }).catch((error) => {
                    this.setState({loaderClass: ''});
                })
            }
        }else{
            this.setState({loaderClass: 'loading'});
            let shareData = {};
            shareData.lead_id      = this.state.shareData.lead_id;
            shareData.dealer_id    = this.props.dealer_id;
            shareData.dealer_id_hash    = this.props.dealer_id_hash;
            shareData.message      = this.state.messageText;
            shareData.mobile       = this.state.shareData.lead_mobile;
            shareData.share_option = share_option;
            shareData.share_type   = share_type;
            MasterService.post('lead/lead/shareLeadSMS', shareData).then((response)=>{
                if (response.status == 200 && response.data.status == 200) {
                    toast.success(response.data.message);
                    if (typeof this.props.modalClose === 'function') {
                        this.props.modalClose();
                    }
                    if(share_type === 'whatsapp'){
                        this.setState({loaderClass: ''});
                        window.open(
                            `https://api.whatsapp.com/send?phone=${this.state.shareData.lead_mobile}&text=${this.state.messageText}`,
                            '_blank'
                        );
                    }
                }
            })
        }  
    }

    validateForm = () => {
        let leadSharePostData = this.state.leadSharePostData;
        let errors = this.state.errors;
        let formIsValid = true;
        if (!leadSharePostData["car_id"] || leadSharePostData["car_id"].length < 1) {
            errors.car_id = this.props.t('validation.select_car');
            formIsValid = false;
        }
        if (errors["email"]) {
            errors.email = this.props.t('validation.cust_email_invalid');
            formIsValid = false;
        }
        if(!leadSharePostData['email'] || leadSharePostData['email'] == null){
            errors.email = this.props.t('validation.cust_email_invalid');
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    cancelPopup = () => {
        if (typeof this.props.modalClose === 'function') {
            this.props.modalClose();
        }
    }

    handleInputChange = (event) => {
        let leadSharePostData = { ...this.state.leadSharePostData };
        let errors = { ...this.state.errors };
        let emailPattern = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
        leadSharePostData.email = event.target.value;
        if (!emailPattern.test(event.target.value)) {
            errors[event.target.name] = this.props.t('validation.cust_email_invalid');
        } else {
            delete errors[event.target.name];
        }
        this.setState({ leadSharePostData: leadSharePostData, errors: errors });
    }

    handleRadioShareType = (fieldName, fieldData)=>{
        if(fieldData.target.value !== 'car_detail'){
            this.generateMessageBody(fieldData.target.value);
        }else{
            this.setState({messageText: ''});
        }
        this.setState({share_option:fieldData.target.value });
    }

    generateMessageBody = (option_type, selectedCarDetails = [])=>{
        const lang = (secureStorage.getItem('lang')) ? secureStorage.getItem('lang') : 'en';
        let dealerDetails = {...this.state.dealerInfo};
        let dealerInfo = this.props.dealerInfo;
        let message_body = "";
        let message_footer = `\n\nDownload our App now to view latest offers\nAndroid App:${dealerInfo.android_app_link} \n\nRegards,\n${dealerDetails.organization}\n${dealerDetails.dealership_contact}`;
        if(lang === 'id'){
            message_footer = `\n\nDownload applikasi kami untuk mengetahui penawaran menarik lainnya\nApplikasi Android:${dealerInfo.android_app_link} \n\nRegards,\n${dealerDetails.organization}\n${dealerDetails.dealership_contact}`;
        }
        if(option_type === 'dealer_location'){
            message_body = `Hi Customer,\n\nI am ${dealerDetails.contact}.${'EMAIL._buyer_lead._name'} from ${dealerInfo.organization}. My dealership location is http://maps.google.com/maps?q=${dealerInfo.latitude},${dealerInfo.longitude}`;
            if(lang === 'id'){
                message_body = `Hai Customer,\n\nSaya ${dealerDetails.contact}.${'EMAIL._buyer_lead._name'} dari ${dealerInfo.organization}. Lokasi dealer kami berada di http://maps.google.com/maps?q=${dealerInfo.latitude},${dealerInfo.longitude}`;
            }
        }
        if(option_type === 'reminder'){
            message_body = `Hi Customer,\n\nI am ${dealerDetails.contact}.${'EMAIL._buyer_lead._name'} from ${dealerInfo.organization}. You were interested in buying a used car. Please suggest suitable time to get in touch with you or call me @${dealerDetails.dealership_contact}`;
            if(lang === 'id'){
                message_body = `Hai Customer,\n\nSaya ${dealerDetails.contact}.${'EMAIL._buyer_lead._name'} dari ${dealerInfo.organization}. Anda tertarik dalam pembelian mobil bekas. Mohon menginfokan ketersediaan waktu anda untuk kami hubungi lebih lanjut @${dealerDetails.dealership_contact}`;
            }
        }
        if(option_type === 'car_detail'){
            message_body = `Hi Customer,\n\nPlease have a look at ${selectedCarDetails.make} ${selectedCarDetails.modelVersion}[${selectedCarDetails.fuel_type}] ${selectedCarDetails.uc_colour} color ${selectedCarDetails.display_reg_date}, ${selectedCarDetails.km_driven} kms driven @${selectedCarDetails.display_price}- ${selectedCarDetails.web_url}`;
            if(lang === 'id'){
                message_body = `Hai Customer,\n\nSilahkan lihat ${selectedCarDetails.make} ${selectedCarDetails.modelVersion}[${selectedCarDetails.fuel_type}] ${selectedCarDetails.uc_colour} warna ${selectedCarDetails.display_reg_date}, ${selectedCarDetails.km_driven} kms penggemudi @${selectedCarDetails.display_price}- ${selectedCarDetails.web_url}`;
            }
        }
        let completeMsg = message_body+message_footer;
        this.setState({messageText: completeMsg});
    }

    handleCarDetailsRadio = (event)=>{
        let index = (event.target.value) ? event.target.value : 0;
        let carDetails = [...this.state.carDetails];
        let selectedCarDetails = carDetails[index];
        this.generateMessageBody('car_detail', selectedCarDetails);
    }

    textAreaChange = (event)=>{

    }

    render() {
        let  lead_data  =  (secureStorage.getItem('loginUserInfo') && secureStorage.getItem('loginUserInfo')['data'] && secureStorage.getItem('loginUserInfo')['data']['lead_data']) ? secureStorage.getItem('loginUserInfo')['data']['lead_data'] : {}
        const { share_type, shareData, carDetails, leadSharePostData, errors, share_option, messageText } = this.state;
        return (
            <div className="row">
                <div className="col-md-12">
                    <ul className="list-inline nav-tabs">
                        {
                            lead_data && +lead_data['LEAD_SHARE_WHATSAPP']
                            ?
                                <li data-key="whatsapp" className={"nav-item " + (share_type === 'whatsapp' ? "active" : " ")} onClick={this.handleTabClick}><div>whatsapp</div></li>
                            :
                                ''
                        }

                        {
                            lead_data && +lead_data['LEAD_SHARE_EMAIL']
                            ?
                                <li data-key="email" className={"nav-item " + (share_type === 'email' ? "active" : " ")} onClick={this.handleTabClick}><div>Email</div></li>
                            :
                                ''
                        }

                        {
                            lead_data && +lead_data['LEAD_SHARE_SMS']
                            ?
                                <li data-key="sms" className={"nav-item " + (share_type === 'sms' ? "active" : " ")} onClick={this.handleTabClick}><div>SMS</div></li>
                            :
                                ''
                        }
                        
                    </ul>
                </div>
                {share_type === 'email' ?
                    <div className="col-md-12">
                        <InputField
                            inputProps={{
                                id: "email",
                                type: "text",
                                placeholder: "Email",
                                name: "email",
                                autocompleate: "off",
                                label: "Enter Email",
                                value: leadSharePostData.email || '',
                                refs: "email",
                                dataerror: errors.email || ''
                            }}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    :
                    <div className="col-md-12">
                        <InputField
                            inputProps={{
                                id: "mobile",
                                type: "text",
                                placeholder: "Mobile",
                                name: "mobile",
                                autocompleate: "off",
                                label: "Enter Mobile No.",
                                readOnly: true,
                                value: shareData.lead_mobile || ''
                            }}
                        />
                    </div>
                }
                <div className="col-md-12 sharepopup form-group">
                
                
                {errors.car_id ? <span className="errors text-danger">{errors.car_id}</span> : ''}
                <div className="sharedetail-scroll">
                    {share_type === 'email' ?
                        carDetails.length > 0 && carDetails.map((carData, index) =>
                            
                                <div key={index} className="share-listcar ">
                                <InputField
                                    inputProps={{
                                        id: "car_" + carData.id,
                                        type: "checkbox",
                                        name: "car_" + carData.id,
                                        label: carData.make + ' ' + carData.model,
                                        carid: carData.id,
                                    }}
                                    onChange={this.handleCheckboxChange}
                                />
                                
                                <div className="row">
                                    <div className="col-md-4"><i className="icons-normal"></i>{carData.display_price}</div>
                                    <div className="col-md-4"><i className="ic-colors icons-normal"></i> {carData.uc_colour}</div>
                                    <div className="col-md-4"><i className="ic-cd-fuel icons-normal"></i> {carData.fuel_type}</div>
                                    <div className="col-md-4"><i className="ic-cd-kilometer icons-normal"></i> {carData.km_driven}</div>
                                    <div className="col-md-4"><i className="ic-rc-file icons-normal"></i> {carData.reg_no}</div>
                                    <div className="col-md-4"><i className="ic-cd-year icons-normal"></i> {carData.display_reg_date}</div>
                                </div>
                            </div>
                        )
                        
                        :
                        
                        <div className="row">
                                <div className="col-sm-12  rc-inline form-group">
                                    <RadioBox type="radio" name="share_option" id="reminder" value="reminder" label="Reminder" checked={(share_option === 'reminder') ? true : false} onChange={this.handleRadioShareType.bind(this, 'share_option')} />
                                    <RadioBox type="radio" name="share_option" id="car_detail" value="car_detail" label="Car Detail" checked={(share_option === 'car_detail') ? true : false} onChange={this.handleRadioShareType.bind(this, 'share_option')} />
                                    <RadioBox type="radio" name="share_option" id="dealer_location" value="dealer_location" label="Dealer Location" checked={(share_option === 'dealer_location') ? true : false} onChange={this.handleRadioShareType.bind(this, 'share_option')} />
                                </div>

                            {  share_option ==='car_detail' ?  
                                carDetails.map((carData, index) =>
                                <div className="col-sm-12" key={index}>
                                <div className="share-listcar">
                                    <RadioBox type="radio" onChange={this.handleCarDetailsRadio.bind(this)} id={index+'_car_option'} name="select_car_option" value={index || ''} label={carData.make + ' ' + carData.model}></RadioBox>
                                    <div className=" clearfix">
                                        <div className="col-md-4"><i className="ic-rupees-line icons-normal"></i>{carData.display_price}</div>
                                        <div className="col-md-4"><i className="ic-colors icons-normal"></i> {carData.uc_colour}</div>
                                        <div className="col-md-4"><i className="ic-cd-fuel icons-normal"></i> {carData.fuel_type}</div>
                                        <div className="col-md-4"><i className="ic-cd-kilometer icons-normal"></i> {carData.km_driven}</div>
                                        <div className="col-md-4"><i className="ic-rc-file icons-normal"></i> {carData.reg_no}</div>
                                        <div className="col-md-4"><i className="ic-cd-year icons-normal"></i> {carData.display_reg_date}</div>
                                    </div>
                                </div>
                                </div>
                                ) : ''
                            } 
                            <div className="col-sm-12 form-group">
                                <textarea onChange={this.textAreaChange} rows='4' className="form-control" name="share_message" value={messageText}></textarea>
                            </div>
                        </div>
                    }
                    </div>
                </div>
                <div className="col-xs-12 text-right clearfix">
                        <button type="reset" onClick={this.cancelPopup} name="reset" id="reset" className="btn btn-link mrg-r15">Cancel</button>
                        <button type="submit" onClick={this.submitLeadShare} name="share_lead" id="share_lead" className={"btn btn-primary " + this.state.loaderClass}>Send</button>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        dealerInfo: state.dealerData,
        userInfo: state.userData,
        dealer_id: state.dealer_id,
        dealer_id_hash: state.dealerData.dealer_id_hash
    };
}
export default withTranslation('lead')(connect(mapStateToProps, null)(ShareLead));